export default {
  headers: [
    {
      id: 'hiddenMiniHeader',
      logo: {
        alt: 'logo',
        src: '',
        url: '',
      },
      breakpoint: -1,

      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: 'on-zeca-hidden-mini-header',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'miniHeader',
      logo: {
        alt: 'logo',
        src: '',
      },
      breakpoint: 529,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-zeca-miniHeader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-zeca-miniHeader-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'header-house-item',
            iconSet: 'fa',
            className: 'home',
            url: 'https://www.brentgermanyteam.com/',
          },
          {
            id: 'header-login-item',
            content: 'SIGN IN / REGISTER',
            url: 'https://www.brentgermanyteam.com/search/property-tracker/',
            className: 'on-zeca-special-item',
          },
          {
            id: 'header-phone-item',
            content: '(972) 210-0304',
            url: 'tel:(972) 210-0304',
            className: 'on-zeca-special-item',
          },
          {
            id: 'header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/dallasareahomes/',
          },
          {
            id: 'header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCEBY0Iugo9C-FzCbODSz0GQ',
          },
          {
            id: 'header-twitter-item',
            iconSet: 'fab',
            className: 'twitter',
            url: 'https://twitter.com/bgermanyteam',
          },
          {
            id: 'header-linkedin-item',
            iconSet: 'fab',
            className: 'linkedin-in',
            url: 'https://www.linkedin.com/company-beta/24997640/',
          },
          {
            id: 'header-gplus-item',
            iconSet: 'fab',
            className: 'google-plus-g',
            url: 'https://plus.google.com/+BrentGermanyTeamPlano',
          },
          {
            id: 'header-pinterest-item',
            iconSet: 'fab',
            className: 'pinterest-p',
            url: 'https://www.pinterest.com/bgermanyteam/',
          },
          {
            id: 'header-instagram-item',
            iconSet: 'fab',
            className: 'instagram',
            url: 'https://www.instagram.com/brentgermanyteam/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: 'on-zeca-header-logo',
        },
        type: 'mobile-content', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          className: '',
          content: 'Open',
          hide: true,
        },
        headerClassName: 'on-zeca-miniHeader-mobile',
        headerClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-zeca-miniHeader-mobile-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'mobile-header-house-item',
            iconSet: 'fa',
            className: 'home',
            url: 'https://www.brentgermanyteam.com/',
          },
          {
            id: 'mobile-header-login-item',
            content: 'SIGN IN / REGISTER',
            url: 'https://www.brentgermanyteam.com/search/property-tracker/',
            className: 'on-zeca-special-item',
          },
          {
            id: 'mobile-header-phone-item',
            content: '(972) 210-0304',
            url: 'tel:(972) 210-0304',
            className: 'on-zeca-special-item',
          },
          {
            id: 'mobile-header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/dallasareahomes/',
          },
          {
            id: 'mobile-header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCEBY0Iugo9C-FzCbODSz0GQ',
          },
          {
            id: 'mobile-header-twitter-item',
            iconSet: 'fab',
            className: 'twitter',
            url: 'https://twitter.com/bgermanyteam',
          },
          {
            id: 'mobile-header-linkedin-item',
            iconSet: 'fab',
            className: 'linkedin-in',
            url: 'https://www.linkedin.com/company-beta/24997640/',
          },
          {
            id: 'mobile-header-gplus-item',
            iconSet: 'fab',
            className: 'google-plus-g',
            url: 'https://plus.google.com/+BrentGermanyTeamPlano',
          },
          {
            id: 'mobile-header-pinterest-item',
            iconSet: 'fab',
            className: 'pinterest-p',
            url: 'https://www.pinterest.com/bgermanyteam/',
          },
          {
            id: 'mobile-header-instagram-item',
            iconSet: 'fab',
            className: 'instagram',
            url: 'https://www.instagram.com/brentgermanyteam/',
          },
        ],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/zeca/logo.jpg',
        url: 'https://www.brentgermanyteam.com/',
      },
      breakpoint: 1019,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-zeca-header-logo-container',
          imageClassName: 'on-zeca-header-logo',
        },
        headerClassName: 'on-zeca-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-zeca-header-item',
        menuClassName: 'on-zeca-header-menu',
        menuLabelActiveClassName: 'on-zeca-header-folder-item-open',
        menuItemClassName: 'on-zeca-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'search-item',
            content: 'SEARCH',
            url: 'https://www.brentgermanyteam.com/search/search-form/',
            menuItems: [
              {
                id: 'search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.brentgermanyteam.com/search/search-form/',
              },
              {
                id: 'search-item-map',
                content: 'Search by Map',
                url: 'https://www.brentgermanyteam.com/search/results/?searchtype=3',
              },
              {
                id: 'search-item-community',
                content: 'Search by Community',
                url: 'https://www.brentgermanyteam.com/communities/',
              },
              {
                id: 'search-item-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.brentgermanyteam.com/featured-listings/',
              },
              {
                id: 'search-item-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.brentgermanyteam.com/search/property-tracker/',
              },
              {
                id: 'search-item-market-stats',
                content: 'Market Statistics',
                url: 'https://www.brentgermanyteam.com/market-statistics/',
              },
            ],
          },
          {
            id: 'menu-item-communities',
            content: 'COMMUNITIES',
            url: 'https://www.brentgermanyteam.com/communities/',
            menuItems: [
              {
                id: 'submenu-item-allen',
                content: 'Allen',
                url: 'https://www.brentgermanyteam.com/allen-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-dallas',
                content: 'Dallas',
                url: 'https://www.brentgermanyteam.com/dallas-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-frisco',
                content: 'Frisco',
                url: 'https://www.brentgermanyteam.com/frisco-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-sf-lewisville',
                content: 'Lewisville',
                url: 'https://www.brentgermanyteam.com/lewisville-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-mckinney',
                content: 'McKinney',
                url: 'https://www.brentgermanyteam.com/mckinney-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-plano',
                content: 'Plano',
                url: 'https://www.brentgermanyteam.com/plano-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-richardson',
                content: 'Richardson',
                url: 'https://www.brentgermanyteam.com/richardson-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-thecolony',
                content: 'The Colony',
                url: 'https://www.brentgermanyteam.com/the-colony-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-carrollton',
                content: 'Carrollton',
                url: 'https://www.brentgermanyteam.com/carrollton-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-flowermound',
                content: 'Flower Mound',
                url: 'https://www.brentgermanyteam.com/flower-mound-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-highland',
                content: 'Highland Park',
                url: 'https://www.brentgermanyteam.com/highland-park-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-littleelm',
                content: 'Little Elm',
                url: 'https://www.brentgermanyteam.com/little-elm-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-braunfels',
                content: 'New Braunfels',
                url: 'https://www.brentgermanyteam.com/new-braunfels-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-prosper',
                content: 'Prosper',
                url: 'https://www.brentgermanyteam.com/prosper-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-sanantonio',
                content: 'San Antonio',
                url: 'https://www.brentgermanyteam.com/san-antonio-tx-homes-for-sale/',
              },
              {
                id: 'submenu-item-wylie',
                content: 'Wylie',
                url: 'https://www.brentgermanyteam.com/wylie-tx-homes-for-sale/',
              },
            ],
          },
          {
            id: 'menu-item-tradein',
            content: 'TRADE-IN',
            url: 'https://trade-in.brentgermanyteam.com',
          },
          {
            id: 'menu-item-cashoffer',
            content: 'CASH OFFER',
            url: 'https://cashoffer.brentgermanyteam.com',
          },
          {
            id: 'menu-item-buyers',
            content: 'BUYERS',
            url: 'https://www.brentgermanyteam.com/buyers/',
            menuItems: [
              {
                id: 'menu-item-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.brentgermanyteam.com/buyers/',
              },
              {
                id: 'menu-item-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.brentgermanyteam.com/buyers/mortgage-calculator/',
              },
              {
                id: 'menu-item-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.brentgermanyteam.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'menu-item-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.brentgermanyteam.com/buyers/first-time-buyers/',
              },
              {
                id: 'menu-item-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.brentgermanyteam.com/buyers/making-an-offer/',
              },
              {
                id: 'menu-item-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.brentgermanyteam.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'menu-item-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.brentgermanyteam.com/buyers/escrow-now-what/',
              },
              {
                id: 'menu-item-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.brentgermanyteam.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'menu-item-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.brentgermanyteam.com/buyers/personalized-home-search/',
              },
              {
                id: 'menu-item-buyers-heroes-hub',
                content: 'Heroes Hub',
                url: 'https://www.brentgermanyteam.com/buyers/heroes-hub/',
              },
              {
                id: 'menu-item-buyers-service-providers',
                content: 'Service Providers - Brent Germany Team',
                url: 'https://www.brentgermanyteam.com/buyers/service-providers/',
              },
            ],
          },
          {
            id: 'menu-item-sellers',
            content: 'SELLERS',
            url: 'https://www.brentgermanyteam.com/sellers/',
            menuItems: [
              {
                id: 'menu-item-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.brentgermanyteam.com/sellers/',
              },
              {
                id: 'menu-item-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.brentgermanyteam.com/sellers/free-market-analysis/',
              },
              {
                id: 'menu-item-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/pricing-your-home/',
              },
              {
                id: 'menu-item-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/marketing-your-home/',
              },
              {
                id: 'menu-item-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/showing-your-home/',
              },
              {
                id: 'menu-item-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.brentgermanyteam.com/sellers/adding-value/',
              },
              {
                id: 'menu-item-sellers-how-we-sell',
                content: 'How We Sell Homes Fast',
                url: 'https://www.brentgermanyteam.com/sellers/how-do-we-sell-homes-so-fast/',
              },
            ],
          },
          {
            id: 'menu-item-about-us',
            content: 'ABOUT US',
            url: 'https://www.brentgermanyteam.com/about/',
            menuItems: [
              {
                id: 'submenu-item-meet-team',
                content: 'Meet The Team',
                url: 'https://www.brentgermanyteam.com/about/',
              },
              {
                id: 'submenu-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.brentgermanyteam.com/about/testimonials/',
              },
              {
                id: 'submenu-item-join-our-team',
                content: 'Join Our Team',
                url: 'https://www.brentgermanyteam.com/about/join-our-team/',
              },
            ],
          },
          {
            id: 'menu-item-blog',
            content: 'BLOG',
            url: 'https://www.brentgermanyteam.com/blog/',
          },
          {
            id: 'menu-item-blog',
            content: 'CONTACT',
            url: 'https://www.brentgermanyteam.com/contact/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-zeca-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-zeca-header-mobile',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-zeca-header-mobile-menu',
        itemClassName: 'on-zeca-header-mobile-item',
        menuClassName: 'on-zeca-header-mobile-folder',
        menuLabelActiveClassName: 'on-zeca-header-mobile-label-active',
        menuItemClassName: 'on-zeca-header-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'mobile-search-item',
            content: 'SEARCH',
            menuItems: [
              {
                id: 'mobile-search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.brentgermanyteam.com/search/search-form/',
              },
              {
                id: 'mobile-search-item-map',
                content: 'Search by Map',
                url: 'https://www.brentgermanyteam.com/search/results/?searchtype=3',
              },
              {
                id: 'mobile-search-item-community',
                content: 'Search by Community',
                url: 'https://www.brentgermanyteam.com/communities/',
              },
              {
                id: 'mobile-search-item-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.brentgermanyteam.com/featured-listings/',
              },
              {
                id: 'mobile-search-item-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.brentgermanyteam.com/search/property-tracker/',
              },
              {
                id: 'mobile-search-item-market-stats',
                content: 'Market Statistics',
                url: 'https://www.brentgermanyteam.com/market-statistics/',
              },
            ],
          },
          {
            id: 'mobile-menu-item-communities',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'mobile-submenu-item-allen',
                content: 'Allen',
                url: 'https://www.brentgermanyteam.com/allen-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-carrollton',
                content: 'Carrollton',
                url: 'https://www.brentgermanyteam.com/carrollton-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-dallas',
                content: 'Dallas',
                url: 'https://www.brentgermanyteam.com/dallas-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-flowermound',
                content: 'Flower Mound',
                url: 'https://www.brentgermanyteam.com/flower-mound-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-frisco',
                content: 'Frisco',
                url: 'https://www.brentgermanyteam.com/frisco-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-highland',
                content: 'Highland Park',
                url: 'https://www.brentgermanyteam.com/highland-park-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-sf-lewisville',
                content: 'Lewisville',
                url: 'https://www.brentgermanyteam.com/lewisville-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-littleelm',
                content: 'Little Elm',
                url: 'https://www.brentgermanyteam.com/little-elm-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-mckinney',
                content: 'McKinney',
                url: 'https://www.brentgermanyteam.com/mckinney-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-braunfels',
                content: 'New Braunfels',
                url: 'https://www.brentgermanyteam.com/new-braunfels-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-plano',
                content: 'Plano',
                url: 'https://www.brentgermanyteam.com/plano-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-prosper',
                content: 'Prosper',
                url: 'https://www.brentgermanyteam.com/prosper-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-richardson',
                content: 'Richardson',
                url: 'https://www.brentgermanyteam.com/richardson-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-sanantonio',
                content: 'San Antonio',
                url: 'https://www.brentgermanyteam.com/san-antonio-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-thecolony',
                content: 'The Colony',
                url: 'https://www.brentgermanyteam.com/the-colony-tx-homes-for-sale/',
              },
              {
                id: 'mobile-submenu-item-wylie',
                content: 'Wylie',
                url: 'https://www.brentgermanyteam.com/wylie-tx-homes-for-sale/',
              },
            ],
          },
          {
            id: 'mobile-menu-item-tradein',
            content: 'TRADE-IN',
            url: 'https://trade-in.brentgermanyteam.com',
          },
          {
            id: 'mobile-menu-item-cashoffer',
            content: 'CASH OFFER',
            url: 'https://cashoffer.brentgermanyteam.com',
          },
          {
            id: 'mobile-menu-item-buyers',
            content: 'BUYERS',
            menuItems: [
              {
                id: 'mobile-menu-item-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.brentgermanyteam.com/buyers/',
              },
              {
                id: 'mobile-menu-item-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.brentgermanyteam.com/buyers/mortgage-calculator/',
              },
              {
                id: 'mobile-menu-item-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.brentgermanyteam.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'mobile-menu-item-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.brentgermanyteam.com/buyers/first-time-buyers/',
              },
              {
                id: 'mobile-menu-item-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.brentgermanyteam.com/buyers/making-an-offer/',
              },
              {
                id: 'mobile-menu-item-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.brentgermanyteam.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'mobile-menu-item-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.brentgermanyteam.com/buyers/escrow-now-what/',
              },
              {
                id: 'mobile-menu-item-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.brentgermanyteam.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'mobile-menu-item-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.brentgermanyteam.com/buyers/personalized-home-search/',
              },
              {
                id: 'mobile-menu-item-buyers-heroes-hub',
                content: 'Heroes Hub',
                url: 'https://www.brentgermanyteam.com/buyers/heroes-hub/',
              },
              {
                id: 'mobile-menu-item-buyers-service-providers',
                content: 'Service Providers - Brent Germany Team',
                url: 'https://www.brentgermanyteam.com/buyers/service-providers/',
              },
            ],
          },
          {
            id: 'mobile-menu-item-sellers',
            content: 'SELLERS',
            menuItems: [
              {
                id: 'mobile-menu-item-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.brentgermanyteam.com/sellers/',
              },
              {
                id: 'mobile-menu-item-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.brentgermanyteam.com/sellers/free-market-analysis/',
              },
              {
                id: 'mobile-menu-item-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/pricing-your-home/',
              },
              {
                id: 'mobile-menu-item-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/marketing-your-home/',
              },
              {
                id: 'mobile-menu-item-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.brentgermanyteam.com/sellers/showing-your-home/',
              },
              {
                id: 'mobile-menu-item-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.brentgermanyteam.com/sellers/adding-value/',
              },
              {
                id: 'mobile-menu-item-sellers-how-we-sell',
                content: 'How We Sell Homes Fast',
                url: 'https://www.brentgermanyteam.com/sellers/how-do-we-sell-homes-so-fast/',
              },
            ],
          },
          {
            id: 'mobile-menu-item-about-us',
            content: 'ABOUT US',
            menuItems: [
              {
                id: 'mobile-submenu-item-meet-team',
                content: 'Meet The Team',
                url: 'https://www.brentgermanyteam.com/about/',
              },
              {
                id: 'mobile-submenu-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.brentgermanyteam.com/about/testimonials/',
              },
              {
                id: 'mobile-submenu-item-join-our-team',
                content: 'Join Our Team',
                url: 'https://www.brentgermanyteam.com/about/join-our-team/',
              },
            ],
          },
          {
            id: 'mobile-menu-item-blog',
            content: 'BLOG',
            url: 'https://www.brentgermanyteam.com/blog/',
          },
          {
            id: 'mobile-menu-item-blog',
            content: 'CONTACT',
            url: 'https://www.brentgermanyteam.com/contact/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-zeca-footer-second',
      display: true,
      className: 'on-zeca-footer-section-two',
      sectionClassName: 'on-zeca-footer-section-container on-zeca-footer-section-container-two',
      columns: [
        {
          id: 'f-2-col-1',
          className: 'on-zeca-footer-column col-md-3 col-sm-6 on-zeca-footer-logo-col',
          style: {},
          items: [
            {
              id: 'f-2-col-1-img',
              type: 'image',
              className: 'on-zeca-footer-image',
              style: {},
              linkUrl: 'https://www.brentgermanyteam.com/',
              url: 'https://si-homelight.s3.amazonaws.com/sites/zeca/logo-footer.png',
            },
            {
              id: 'f-2-col-1-img-2',
              type: 'image',
              className: 'on-zeca-footer-image-equal',
              style: {},
              url: 'https://si-homelight.s3.amazonaws.com/sites/zeca/logo-footer-equal-realtor.png',
            },
            {
              id: 'f-2-col-1-item-1',
              type: 'heading',
              className: '',
              data: 'TREC Consumer Protection Notice',
              url: 'https://www.trec.texas.gov/forms/consumer-protection-notice',
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              className: '',
              data: 'TREC Information About Brokerage Services',
              url: 'https://www.brentgermanyteam.com/res/includes/IABS-Germany.pdf?v=2',
            },
          ],
        },
        {
          id: 'f-2-col-2',
          className: 'on-zeca-footer-column col-md-3 col-sm-6',
          style: {},
          items: [
            {
              id: 'f-2-col-2-h1',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'OFFICE',
            },
            {
              id: 'f-2-col-2-item-1',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'The Brent Germany Team',
            },
            {
              id: 'f-2-col-2-item-2',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Real Broker, LLC',
            },
            {
              id: 'f-2-col-2-item-3',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Brent Germany, Team Leader',
            },
            {
              id: 'f-2-col-2-item-4',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: '2626 Cole Ave, Ste 300',
            },
            {
              id: 'f-2-col-2-item-5',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Dallas, TX 75206',
            },
            {
              id: 'f-2-col-2-item-6',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: '(972) 210-0304',
            },
            {
              id: 'f-2-col-2-item-7',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Dallas Fort Worth Real Estate',
            },
            {
              id: 'f-2-col-2-soc',
              type: 'socialIcons',
              itemClassName: 'on-zeca-footer-two-social',
              className: 'on-zeca-footer-three-social',
              items: [
                {
                  id: 'footer-fcb',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/dallasareahomes/',
                  style: { color: '#3E5A9B' },
                },
                {
                  id: 'footer-yt',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  url: 'https://www.youtube.com/channel/UCEBY0Iugo9C-FzCbODSz0GQ',
                  style: { color: '#BD0001' },
                },
                {
                  id: 'footer-twt',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  url: 'https://twitter.com/bgermanyteam',
                  style: { color: '#14B2EF' },
                },
                {
                  id: 'footer-li',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'https://www.linkedin.com/company-beta/24997640/',
                  style: { color: '#007BB7' },
                },
                {
                  id: 'footer-li',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'google-plus-g',
                  url: 'https://plus.google.com/+BrentGermanyTeamPlano',
                  style: { color: '#DE4B3B' },
                },
                {
                  id: 'footer-li',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'pinterest-p',
                  url: 'https://www.pinterest.com/bgermanyteam/',
                  style: { color: '#CF353D' },
                },
                {
                  id: 'footer-li',
                  className: 'on-zeca-footer-three-social-item',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/brentgermanyteam/',
                  style: { color: '#5080A4' },
                },
              ],
            },
          ],
        },
        {
          id: 'f-col-2',
          className: 'on-zeca-footer-column col-md-2 col-sm-6',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'ABOUT US',
            },
            {
              id: 'col-two-menu-1',
              type: 'menu',
              className: 'on-zeca-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-1',
                  data: 'Meet the Team',
                  url: 'https://www.brentgermanyteam.com/about/',
                },
                {
                  id: 'col-three-item-2',
                  data: 'Client Testimonials',
                  url: 'https://www.brentgermanyteam.com/about/testimonials/',
                },
              ],
            },
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'RESOURCES',
            },
            {
              id: 'col-two-menu-2',
              type: 'menu',
              className: 'on-zeca-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-3',
                  data: 'Blog',
                  url: 'https://www.brentgermanyteam.com/blog',
                },
                {
                  id: 'col-three-item-4',
                  data: 'Buyers',
                  url: 'https://www.brentgermanyteam.com/buyers/',
                },
                {
                  id: 'col-three-item-5',
                  data: 'Sellers',
                  url: 'https://www.brentgermanyteam.com/sellers/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-zeca-footer-column  col-md-4 col-sm-6',
          style: {},
          items: [
            {
              id: 'col-four-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'COMMUNITIES',
            },
            {
              id: 'col-four-menu',
              type: 'menu',
              className: 'on-zeca-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-four-menu-item-allen',
                  data: 'Allen',
                  url: 'https://www.brentgermanyteam.com/allen-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-carrollton',
                  data: 'Carrollton',
                  url: 'https://www.brentgermanyteam.com/carrollton-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-dallas',
                  data: 'Dallas',
                  url: 'https://www.brentgermanyteam.com/dallas-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-flowermound',
                  data: 'Flower Mound',
                  url: 'https://www.brentgermanyteam.com/flower-mound-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-frisco',
                  data: 'Frisco',
                  url: 'https://www.brentgermanyteam.com/frisco-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-highland',
                  data: 'Highland Park',
                  url: 'https://www.brentgermanyteam.com/highland-park-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-lakewood',
                  data: 'Lakewood',
                  url: 'https://www.brentgermanyteam.com/dallas-tx-homes-for-sale/lakewood/',
                },
                {
                  id: 'col-four-menu-item-sf-lewisville',
                  data: 'Lewisville',
                  url: 'https://www.brentgermanyteam.com/lewisville-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-littleelm',
                  data: 'Little Elm',
                  url: 'https://www.brentgermanyteam.com/little-elm-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-mckinney',
                  data: 'McKinney',
                  url: 'https://www.brentgermanyteam.com/mckinney-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-braunfels',
                  data: 'New Braunfels',
                  url: 'https://www.brentgermanyteam.com/new-braunfels-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-plano',
                  data: 'Plano',
                  url: 'https://www.brentgermanyteam.com/plano-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-prosper',
                  data: 'Prosper',
                  url: 'https://www.brentgermanyteam.com/prosper-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-richardson',
                  data: 'Richardson',
                  url: 'https://www.brentgermanyteam.com/richardson-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-sanantonio',
                  data: 'San Antonio',
                  url: 'https://www.brentgermanyteam.com/san-antonio-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-thecolony',
                  data: 'The Colony',
                  url: 'https://www.brentgermanyteam.com/the-colony-tx-homes-for-sale/',
                },
                {
                  id: 'col-four-menu-item-university-park',
                  data: 'University Park',
                  url: 'https://www.brentgermanyteam.com/dallas-tx-homes-for-sale/university-park/',
                },
                {
                  id: 'col-four-menu-item-wylie',
                  data: 'Wylie',
                  url: 'https://www.brentgermanyteam.com/wylie-tx-homes-for-sale/',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-zeca-footer-third',
      display: true,
      className: 'on-zeca-footer-section-three',
      sectionClassName: 'on-zeca-footer-section-container on-zeca-footer-section-container-three ',
      columns: [
        {
          id: 'f-3-col-1',
          className: 'col-md-8 on-zeca-f-3-col',
          style: {},
          items: [
            {
              id: 'f-3-col-1-item-2',
              type: 'heading',
              className: '',
              style: {},
              data: '© 2022 Brent Germany Team | Coldwell Banker Realty. All Rights Reserved.',
            },
            {
              id: 'f-3-col-1-item-1',
              type: 'menu',
              className: 'on-zeca-footer-privacy-menu',
              style: {},
              items: [
                {
                  id: 'f-3-col-1-menu-1',
                  data: 'Accessibility',
                  className: 'on-zeca-footer-privacy-menu-item-first',
                  url: 'https://www.brentgermanyteam.com/accessibility/',
                },
                {
                  id: 'f-3-l-1',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-3',
                  data: 'Privacy Policy',
                  url: 'https://www.brentgermanyteam.com/privacy-policy/',
                },
                {
                  id: 'f-3-l-2',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-2',
                  data: 'Terms of Use',
                  url: 'https://www.brentgermanyteam.com/terms-of-service/',
                },
                {
                  id: 'f-3-l-3',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-4',
                  data: 'DMCA Notice',
                  url: 'https://www.brentgermanyteam.com/dmca-notice/',
                },
                {
                  id: 'f-3-l-4',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-5',
                  data: 'Listings Sitemap',
                  url: 'https://www.brentgermanyteam.com/search/site-map/',
                },
                {
                  id: 'f-3-l-5',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-6',
                  data: 'Sitemap',
                  url: 'https://www.brentgermanyteam.com/site-map/',
                },
              ],
            },
          ],
        },
        {
          id: 'f-3-col-3',
          className: 'col-md-4 on-zeca-f-3-menu-col on-zeca-f-3-col',
          style: {},
          items: [
            {
              id: 'f-3-col-3-item',
              type: 'heading',
              className: 'col-md-4',
              itemClassName: 'mb-0',
              data: 'Real Estate Websites by Sierra Interactive',
              url: 'https://www.sierrainteractive.com/',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'The Brent Germany Team',
    agentDescription: 'Top agents in DFW and surrounding areas, TX',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/zeca/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/zeca/hero-bg.jpg',
  },
};
